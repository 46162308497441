import axiosInstance from "./AxiosInstance";

export async function addMenuItem(formData) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  try {
    const res = await axiosInstance.post('/item', formData, config);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function addMenuItemSpecial(formData) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  try {
    console.log("addMenuItemSpecial");
    const res = await axiosInstance.post('/special', formData, config);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteMenuItemSpecial(formData) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  try {
    console.log("addMenuItemSpecial");
    const res = await axiosInstance.del('/special', formData, config);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getMenuItems() {
  try {
    const res = await axiosInstance.get('/item');
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantMenuItems(id) {
  try {
    const res = await axiosInstance.get(`/items/${id}`);
    console.log("merchant items response", res);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateMenuItem(formData, id) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  try {
    const res = await axiosInstance.post(`/item/${id}`, formData, config);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteMenuItem(id) {
  try {
    const res = await axiosInstance.delete(`/item/${id}`);
    return res;
  } catch (error) {
    throw(error);
  }
}
