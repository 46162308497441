import {
  CREATE_ADVERTISING,
  GET_ALL_ADVERTISING,
  GET_ADVERTISING,
  UPDATE_ADVERTISING,
  DELETE_ADVERTISING,
} from "../actions/AdvertisingActions";

const initialState = {
  data: [], // All advertisements
  currentAdvertisement: null, // Single advertisement
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function advertising(state = initialState, action) {
  switch (action.type) {
    case CREATE_ADVERTISING:
      return {
        ...state,
        data: [...state.data, action.payload.data], // Append new advertisement
        errorMessage: action.payload.error
          ? "Error Creating Advertisement"
          : "",
        successMessage: action.payload.error ? "" : "Advertisement Created Successfully",
        showLoading: false,
      };
    case GET_ALL_ADVERTISING:
      return {
        ...state,
        data: action.payload.data, // Set the data to the list of all advertisements
        errorMessage: action.payload.error
          ? "Advertising Data Retrieval Failed"
          : "",
        successMessage: action.payload.error ? "" : "Advertising Data Successfully Retrieved",
        showLoading: false,
      };
    case GET_ADVERTISING:
      return {
        ...state,
        currentAdvertisement: action.payload.data, // Set the current advertisement
        errorMessage: action.payload.error
          ? "Advertising Data Retrieval Failed"
          : "",
        successMessage: action.payload.error ? "" : "Advertising Data Successfully Retrieved",
        showLoading: false,
      };
    case UPDATE_ADVERTISING:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ), // Update the specific advertising item in the list
        errorMessage: action.payload.error
          ? "Error Updating Advertisement"
          : "",
        successMessage: action.payload.error ? "" : "Advertisement Updated Successfully",
        showLoading: false,
      };
    case DELETE_ADVERTISING:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id), // Remove the specific advertising item from the list
        errorMessage: action.payload.error
          ? "Error Deleting Advertisement"
          : "",
        successMessage: action.payload.error ? "" : "Advertisement Deleted Successfully",
        showLoading: false,
      };
    default:
      return state;
  }
}
