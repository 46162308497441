import axiosInstance from "./AxiosInstance";

export function createBankAccount(
  merchant_id,
  bank_name,
  routing_number,
  account_number,
  account_type,
  is_allow_wire,
  first_name,
  last_name,
  address,
  city,
  state,
  zip,
  country,
  banking_institution_name,
  bank_institution_address,
  bank_institution_city,
  bank_institution_state,
  bank_institution_postal_code,
  bank_institution_country,
) {
  const postData = {
    merchant_id,
    bank_name,
    routing_number,
    account_number,
    account_type,
    is_allow_wire,
    first_name,
    last_name,
    address,
    city,
    state,
    zip,
    country,
    banking_institution_name,
    bank_institution_address,
    bank_institution_city,
    bank_institution_state,
    bank_institution_postal_code,
    bank_institution_country,
  }

  return axiosInstance.put(`/bank-accounts/${merchant_id}`, postData);
}

export function getAllBankAccounts() {
 return axiosInstance.get('/bank-accounts');
}

export function getBankAccount(id) {
 return axiosInstance.get(`/bank-accounts/${id}`);
}

// export function updateBankAccount(id) {
//   const config = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     }
//   }

//   return axiosInstance.put(`/bank-accounts/${id}`, config);
// }

export function deleteBankAccount(id) {
  return axiosInstance.delete(`/bank-accounts/${id}`);
}
