import React from "react";
import {
  createAdvertising,
  getAllAdvertising,
  getAdvertising,
  updateAdvertising,
  deleteAdvertising,
} from "../../services/AdvertisingService";
import { formatError } from "../../services/AuthService";
import { merchant } from "../reducers/MerchantReducer";

export const CREATE_ADVERTISING = "CREATE_ADVERTISING"; 
export const GET_ADVERTISING = "GET_ADVERTISING";
export const GET_ALL_ADVERTISING = "GET_ALL_ADVERTISING";
export const UPDATE_ADVERTISING = "UPDATE_ADVERTISING";
export const DELETE_ADVERTISING = "DELETE_ADVERTISING";

export function CreateAdvertising(formData) {
    return (dispatch) => {
      createAdvertising(formData)
        .then((response) => {
          dispatch(CreateAdvertisingAction(response.data));
        })
        .catch((error) => {
          const errorMessage = formatError(error.message);
          console.error(errorMessage);
        });
    };
  }

  export function GetAllAdvertising() {
    return (dispatch) => {
      getAllAdvertising()
        .then((response) => {
          dispatch(GetAllAdvertisingAction(response.data));
        })
        .catch((error) => {
          const errorMessage = formatError(error.message);
          console.error("API call failed", errorMessage);
        });
    };
  }

export function GetAdvertising(id) {
  return (dispatch) => {
    getAdvertising(id)
      .then((response) => {
        dispatch(GetAdvertisingAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  };
}

// export function UpdateAdvertising(formData, id) {
//   return (dispatch) => {
//     updateAdvertising(formData, id)
//       .then((response) => {
//         dispatch(UpdateAdvertisingAction(response.data));
//       })
//       .catch((error) => {
//         const errorMessage = formatError(error.message);
//         console.error(errorMessage);
//       });
//   };
// }

export function DeleteAdvertising(id) {
  return (dispatch) => {
    deleteAdvertising(id)
      .then(() => {
        dispatch({
          type: DELETE_ADVERTISING,
          payload: { id },
        });
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        dispatch({
          type: DELETE_ADVERTISING,
          payload: { id, error: errorMessage },
        });
      });
  };
}

export function CreateAdvertisingAction(data) {
  return {
    type: CREATE_ADVERTISING,
    payload: data,
  };
}

export function GetAllAdvertisingAction(data) {
  return {
    type: GET_ALL_ADVERTISING,
    payload: data,
  };
}

export function GetAdvertisingAction(data) {
  return {
    type: GET_ADVERTISING,
    payload: data,
  };
}

export function UpdateAdvertisingAction(data) {
  return {
    type: UPDATE_ADVERTISING,
    payload: data,
  };
}

export function DeleteAdvertisingAction(data) {
  return {
    type: DELETE_ADVERTISING,
    payload: data,
  };
}
