import React from "react";
import {
  getOrders,
  getSingleOrder,
  getMerchantOrders,
  deleteOrder,
} from "../../services/OrdersService";
import { formatError } from "../../services/AuthService";

export const GET_ORDERS_ACTION = "GET_ORDERS_ACTION";
export const GET_SINGLE_ORDER_ACTION = "GET_SINGLE_ORDER_ACTION";
export const GET_MERCHANT_ORDERS_ACTION = "GET_MERCHANT_ORDERS_ACTION";
export const DELETE_ORDER_ACTION = "DELETE_ORDER_ACTION";

export function GetOrdersAction() {
  return (dispatch) => {
    getOrders()
      .then((response) => {
        dispatch(GetOrdersConfirmationAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error);
        console.log(errorMessage);
      });
  };
}

export function GetSingleOrderAction(orderId) {
  return (dispatch) => {
    getSingleOrder(orderId)
      .then((response) => {
        dispatch(GetSingleOrderConfirmationAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error);
        console.log(errorMessage);
      });
  };
}

export function GetMerchantOrdersAction(merchantId) {
  return (dispatch) => {
    getMerchantOrders(merchantId)
      .then((response) => {
        dispatch(GetMerchantOrdersConfirmationAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error);
        console.log(errorMessage);
      });
  }
}

export function DeleteOrderAction(orderId) {
  return (dispatch) => {
    deleteOrder(orderId)
      .then((response) => {
        dispatch(DeleteOrderAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error);
        console.log(errorMessage);
      });
  };
}

export function GetOrdersConfirmationAction(data) {
  return {
    type: GET_ORDERS_ACTION,
    payload: data,
  };
}

export function GetSingleOrderConfirmationAction(data) {
  return {
    type: GET_SINGLE_ORDER_ACTION,
    payload: data,
  };
}

export function GetMerchantOrdersConfirmationAction(data) {
  return {
    type: GET_MERCHANT_ORDERS_ACTION,
    payload: data,
  }
}

export function DeleteOrderConfirmationAction(data) {
  return {
    type: DELETE_ORDER_ACTION,
    payload: data,
  };
}
