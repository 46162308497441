import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Tab } from "react-bootstrap";
import Select from 'react-select';
import { CreateBankAccount } from "../../../../store/actions/BankAccountsActions";
import CustomStatesSelect from "../../../components/PluginsMenu/Select2/CustomStatesSelect";
import { useNavigate } from "react-router-dom";


import bgimage from "../../../../images/no-img-avatar.png";
import SelectState from "../../Forms/DropdownLists/SelectState";
import { useDispatch, connect } from "react-redux";
import {
  GetMerchantDataAction,
  UpdateMerchantAction,
} from "../../../../store/actions/MerchantActions";
import { Modal, Button } from "react-bootstrap";
import API_CONFIG from "../../../../apiConfig";
import MerchantSettingsNav from "./MerchantSettingsNav";
import { image } from "d3-fetch";
import axiosInstance from "../../../../services/AxiosInstance";

const NotificationBlog = [
  { title: "Expecting a slight delay", time: "now" },
  { title: "Your Order has been pickedup by", time: "1 min ago" },
  { title: "Your will arrive at 3:00 - 4:00pm today.", time: "1 hours ago" },
  { title: "Your will arrive at 5:00 - 6:00pm today", time: "3 hours ago" },
];

const inputBlog = [
  { title: "Moblie Apps(s)" },
  { title: "Api" },
  { title: "Moblie web" },
  { title: "Main website" },
  { title: "Tablet" },
  { title: "Other" },
];

const helpblog = [
  { title: "Check your Booking Status" },
  { title: "Checkout of FoodDesk" },
  { title: "Order Tracking Problem" },
  { title: "Login issue" },
];

const policyBlog = [
  { id: "11", title: "Email address" },
  { id: "12", title: "First name and last name" },
  { id: "13", title: "Phone number" },
  { id: "14", title: "Address,state,pin code and city" },
  { id: "15", title: "Social media profile information" },
  { id: "16", title: "Other" },
];

const MerchantSettings = () => {

  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const merchant = useSelector((state) => state.merchant.data);
  const mediaUrl = API_CONFIG.mediaUrl;
  const location = useLocation();

  useEffect(() => {
    dispatch(GetMerchantDataAction(merchantId));
  }, []);

  //For Image upload in ListBlog
  const [smallModal, setSmallModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [merchantProfileImage, setMerchantProfileImage] = useState(null);
  const [merchantBackgroundImage, setMerchantBackgroundImage] = useState(null);
  const [merchantBackgroundImageBlob, setMerchantBackgroundImageBlob] =
  useState(false);
  const [merchantBackgroundImagePreview, setMerchantBackgroundImagePreview] =
    useState(merchant.background_image || false);
  const [merchantProfileImagePreview, setMerchantProfileImagePreview] =
    useState(merchant.profile_image || false);
  const [merchantProfileImageBlob, setMerchantProfileImageBlob] =
    useState(false);
  const [profileImageUpdated, setProfileImageUpdated] = useState(false);  
  const [backgroundImageUpdated, setBackgroundImageUpdated] = useState(false);  
  const [merchantBusinessName, setMerchantBusinessName] = useState(null);
  const [merchantTaxRate, setMerchantTaxRate] = useState(null);
  const [merchantPhone, setMerchantPhone] = useState(null);
  const [merchantEmail, setMerchantEmail] = useState(null);
  const [merchantAbout, setMerchantAbout] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);
  const [maxCharacterLength, setMaxCharacterLength] = useState(false);
  const [merchantPassword, setMerchantPassword] = useState(null);
  const [merchantAddress, setMerchantAddress] = useState({
    address: merchant.address,
    city: merchant.city,
    state: merchant.state,
    zip: merchant.postal_code,
    address2: "",
  });
  const [merchantLocation, setMerchantLocation] = useState({
    url: `https://www.google.com/maps/embed/v1/MAP_MODE?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    lat: null,
    long: null,
  });

  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountRouting, setBankAccountRouting] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [isAllowWire, setIsAllowWire] = useState(true); 
  const [bankAddress, setBankAddress] = useState("");
  const [bankAddress2, setBankAddress2] = useState("");
  const [bankCity, setBankCity] = useState("");
  const [bankState, setBankState] = useState("");
  const [bankPostalCode, setBankPostalCode] = useState("");
  const [bankCountry, setBankCountry] = useState("");
  const [bankInstitutionName, setBankInstitutionName] = useState("");
  const [bankInstitutionAddress1, setBankInstitutionAddress1] = useState("");
  const [bankInstitutionAddress2, setBankInstitutionAddress2] = useState("");
  const [bankInstitutionCity, setBankInstitutionCity] = useState("");
  const [bankInstitutionState, setBankInstitutionState] = useState("");
  const [bankInstitutionPostalCode, setBankInstitutionPostalCode] = useState("");
  const [bankInstitutionCountry, setBankInstitutionCountry] = useState("");

  const closed = "Closed";


  let errorsObj = {
    businessName: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  };

  const [hours, setHours] = useState([
    { day_of_week: 'Monday', open: '', close: '' },
    { day_of_week: 'Tuesday', open: '', close: '' },
    { day_of_week: 'Wednesday', open: '', close: '' },
    { day_of_week: 'Thursday', open: '', close: '' },
    { day_of_week: 'Friday', open: '', close: '' },
    { day_of_week: 'Saturday', open: '', close: '' },
    { day_of_week: 'Sunday', open: '', close: '' },
  ]);

  const options = [
    { value: "null", label: closed },
    { value: "5:00 AM", label: "5:00 AM" },
    { value: "5:30 AM", label: "5:30 AM" },
    { value: "6:00 AM", label: "6:00 AM" },
    { value: "6:30 AM", label: "6:30 AM" },
    { value: "7:00 AM", label: "7:00 AM" },
    { value: "7:30 AM", label: "7:30 AM" },
    { value: "8:00 AM", label: "8:00 AM" },
    { value: "8:30 AM", label: "8:30 AM" },
    { value: "9:00 AM", label: "9:00 AM" },
    { value: "9:30 AM", label: "9:30 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "12:30 PM", label: "12:30 PM" },
    { value: "1:00 PM", label: "1:00 PM" },
    { value: "1:30 PM", label: "1:30 PM" },
    { value: "2:00 PM", label: "2:00 PM" },
    { value: "2:30 PM", label: "2:30 PM" },
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "3:30 PM", label: "3:30 PM" },
    { value: "4:00 PM", label: "4:00 PM" },
    { value: "4:30 PM", label: "4:30 PM" },
    { value: "5:00 PM", label: "5:00 PM" },
    { value: "5:30 PM", label: "5:30 PM" },
    { value: "6:00 PM", label: "6:00 PM" },
    { value: "6:30 PM", label: "6:30 PM" },
    { value: "7:00 PM", label: "7:00 PM" },
    { value: "7:30 PM", label: "7:30 PM" },
    { value: "8:00 PM", label: "8:00 PM" },
    { value: "8:30 PM", label: "8:30 PM" },
    { value: "9:00 PM", label: "9:00 PM" },
    { value: "9:30 PM", label: "9:30 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "10:30 PM", label: "10:30 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:30 PM", label: "11:30 PM"},
    { value: "12:00 AM", label: "12:00 AM" },
    { value: "12:30 AM", label: "12:30 AM" },
    { value: "1:00 AM", label: "1:00 AM" },
    { value: "1:30 AM", label: "1:30 AM" },
    { value: "2:00 AM", label: "2:00 AM" },
    { value: "2:30 AM", label: "2:30 AM" },
    { value: "3:00 AM", label: "3:00 AM" },
    { value: "3:30 AM", label: "3:30 AM" },
    { value: "4:00 AM", label: "4:00 AM" },
    { value: "4:30 AM", label: "4:30 AM" },
    
];

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const fileHandler = (e, imageType) => {
    const value = e.target.files[0];

    imageType === "background" ? setMerchantBackgroundImage(value) : setMerchantProfileImage(value);
    // Preview image
    const blob = URL.createObjectURL(value);

    imageType === "background" ? setMerchantBackgroundImageBlob(blob) : setMerchantProfileImageBlob(blob);
    imageType === "background" ? setBackgroundImageUpdated(true) : setProfileImageUpdated(true);
  };

  function RemoveImage(imageType) {
    imageType === "background" ? setMerchantBackgroundImageBlob(`${mediaUrl}/${merchant.background_image}`) : setMerchantProfileImageBlob(`${mediaUrl}/${merchant.profile_image}`);
    imageType === "background" ? setMerchantBackgroundImage(`${mediaUrl}/${merchant.background_image}`) : setMerchantProfileImage(`${mediaUrl}/${merchant.profile_image}`);
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setMerchantAddress({ ...merchantAddress, [name]: value });
  };

  useEffect(() => {
    if (merchantAddress.address !== null) {
      convertAddressToLocation();
    }
    return;
  }, [merchantAddress]);

  useEffect(() => {
    console.log(hours);
    console.log("merchant hours", merchant);  
  }, [hours]);

  useEffect(() => {
    if (merchantLocation.lat !== null) {
      console.log("lat/long", merchantLocation.lat, merchantLocation.long);
    }
  }, [merchantLocation]);

  useEffect(() => {
    return () => {
      setMerchantProfileImageBlob(false);
      setMerchantBackgroundImageBlob(false);
    };
  }, [location]); 

  function convertAddressToLocation(){
    const address = merchantAddress.address + " " + merchantAddress.city + " " + merchantAddress.state + " " + merchantAddress.zip;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const { lat, lng } = data.results[0].geometry.location;
        console.log(lat, lng);
        setMerchantLocation({
          url: `https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_API_KEY}&center=${lat},${lng}&zoom=18`,
          lat: lat,
          long: lng,
        });
      })
      .catch((error) => console.log(error));
  }

  function updateCharacterCount(value) {
    setCharacterCount(value.length);
  }

  function updateAboutUs(e) {
    const value = e.target.value;
    setMerchantAbout(value);
    updateCharacterCount(value);
    value.length > 510 ? setMaxCharacterLength(true) : setMaxCharacterLength(false);
  }

  function showPasswordToggle() {
    setShowPassword(!showPassword);
  }

  function handleSave() {
    const formData = new FormData();

    formData.append("_method", "put");
    formData.append(
      "business_name",
      merchantBusinessName !== null ? merchantBusinessName : merchant.business_name
    );
    formData.append(
      "email",
      merchantEmail !== null ? merchantEmail : merchant.email
    );
    formData.append(
      "about",
      merchantAbout !== null ? merchantAbout : merchant.about
    );
    if (merchantPassword !== null && merchantPassword !== undefined && merchantPassword !== "") {
      formData.append("password", merchantPassword);
    } 
    formData.append(
      "profile_image",
      merchantProfileImage !== null ? merchantProfileImage : merchant.profile_image
    );
    formData.append(
      "background_image",
      merchantBackgroundImage !== null ? merchantBackgroundImage : merchant.background_image
    );
    formData.append(
      "phone",
      merchantPhone !== null ? merchantPhone : merchant.phone
    );
    formData.append(
      "tax_rate",
      merchantTaxRate !== null ? merchantTaxRate : merchant.tax_rate
    );
    formData.append("address", merchantAddress.address);
    formData.append("city", merchantAddress.city);
    formData.append("state", merchantAddress.state);
    formData.append("postal_code", merchantAddress.zip);
    formData.append("merchantId", merchantId);
    formData.append("latitude", merchantLocation.lat);
    formData.append("longitude", merchantLocation.long);
    dispatch(UpdateMerchantAction(formData, merchantId));
    const confirmation = document.querySelector(".confirmation-message");
    setProfileImageUpdated(false);
    setBackgroundImageUpdated(false);
    confirmation.textContent = "Profile Updated!";
  }

  function handleHoursSave() {
    const formData = new FormData();
  
    formData.append("_method", "put");
    formData.append("merchant_id", merchantId !== null ? merchantId : merchantId);
  
    hours.forEach((hour, index) => {
      
      formData.append(`hours[${index}][day_of_week]`, hour.day_of_week);
      formData.append(`hours[${index}][open]`, hour.open ? hour.open : merchant.hours[index] ? merchant.hours[index].open_time : null);
      formData.append(`hours[${index}][close]`, hour.close ? hour.close : (merchant.hours[index] ? merchant.hours[index].close_time : null));
    });
  
    axiosInstance.post(`/merchant-hours/${merchantId}`, formData)
      .then((response) => {
        const confirmation = document.querySelector(".hours-confirmation-message");
        confirmation.textContent = "Hours Updated!";
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = document.querySelector(".hours-error-message");
        errorMessage.textContent = "Error! Make sure format is correct. Example: 9:00 AM"; 
      });

  }

  async function handleBankSave() {
    try {
      await dispatch(CreateBankAccount(
        merchantId,
        bankAccountName,
        bankAccountRouting,
        bankAccountNumber,
        bankAccountType,
        isAllowWire,
        firstName,
        LastName,
        bankAddress,
        bankCity,
        bankState,
        bankPostalCode,
        bankCountry,
        bankInstitutionName,
        bankInstitutionAddress1 + ' ' + bankInstitutionAddress2,
        bankInstitutionCity,
        bankInstitutionState,
        bankInstitutionPostalCode,
        bankInstitutionCountry,
      ));
      const confirmation = document.querySelector(".bank-confirmation-message");
      confirmation.textContent = "Bank Updated!";
    } catch (error) {
      console.log(error);
      const errorMessage = document.querySelector(".bank-error-message");
      errorMessage.textContent = "Error! Please check your input fields."; 
    
    }

  }

  return (
    <>
      <div className="row">
        {/* <MerchantSettingsNav /> */}
        <Tab.Container defaultActiveKey="Account">
          <div className="col-xl-8">
            <Tab.Content>
              <Tab.Pane eventKey="Account" key="account">
                <form onSubmit={ handleSubmit(handleSave) }>
                  <div className="setting-right">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="mb-4">Account</h3>

                        { /* Background Image Preview */}
                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative d-flex">
                              <div className="avatar-preview">
                                <p>Background Image</p>
                                <img
                                  src={
                                    backgroundImageUpdated
                                      ? `${merchantBackgroundImageBlob}`
                                      :   merchant.background_image
                                      ? `${mediaUrl}/${merchant.background_image}`
                                      : bgimage
                                  }
                                  alt={
                                    merchantBackgroundImage
                                      ? merchantBackgroundImage.name
                                      : null
                                  }
                                />
                              </div>
                              <div className="change-btn d-flex align-items-center flex-wrap">
                                <input
                                  type="file"
                                  id="merchant-background-image-upload"
                                  {...register("profile_image")}
                                  onChange={(e) => fileHandler(e, "background") }
                                  className="form-control"
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="merchant-background-image-upload"
                                  className="dlab-upload"
                                >
                                  Choose File
                                </label>
                                <button
                                  type="button"
                                  className="btn remove-img ms-2"
                                  onClick={() => RemoveImage("background")}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        { /* Profile Image Preview */}
                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative d-flex">
                              <div className="avatar-preview">
                                <p>Profile Image</p>
                                <img
                                  src={
                                    profileImageUpdated
                                      ? `${merchantProfileImageBlob}`
                                      :   merchant.profile_image
                                      ? `${mediaUrl}/${merchant.profile_image}`
                                      : bgimage
                                  }
                                  alt={
                                    merchantProfileImage
                                      ? merchantProfileImage.name
                                      : null
                                  }
                                />
                              </div>
                              <div className="change-btn d-flex align-items-center flex-wrap">
                                <input
                                  type="file"
                                  id="merchant-profile-image-upload"
                                  {...register("profile_image")}
                                  onChange={(e) => fileHandler(e, "profile") }
                                  className="form-control"
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="merchant-profile-image-upload"
                                  className="dlab-upload"
                                >
                                  Choose File
                                </label>
                                <button
                                  type="button"
                                  className="btn remove-img ms-2"
                                  onClick={() => RemoveImage("profile")}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-sm-6">
                            <div className="setting-input">
                              <label
                                htmlFor="merchant-business-name"
                                className="form-label"
                              >
                                Business Name
                              </label>
                              <input
                                type="text"
                                {...register("merchant-business-name")}
                                onChange={(e) =>
                                  setMerchantBusinessName(e.target.value)
                                }
                                className="form-control"
                                id="merchant-business-name"
                                defaultValue={merchant.business_name}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="merchant-email"
                                className="form-label"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                {...register("merchant-email")}
                                onChange={(e) => setMerchantEmail(e.target.value)}
                                className="form-control"
                                id="merchant-email"
                                defaultValue={merchant.email}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="merchant-about"
                                className="form-label"
                              >
                                About Us
                              </label>
                              <textarea
                                {...register("merchant-about")}
                                onChange={(e) => updateAboutUs(e)}
                                className="form-control"
                                id="merchant-about"
                                defaultValue={merchant.about}
                              />
                              <p className="fs-12 text-end">{characterCount}/510</p>
                              <p className="fs-12 text-end text-danger">{maxCharacterLength && "Max character limit reached!"}</p>
                            </div>
                            <div className="setting-input">
                              <p className="fs-18 mb-2">Your Location</p>
                              <iframe
                                src={`${merchantLocation.url}?key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                                style={{
                                  border: "0",
                                  width: "100%",
                                  minHeight: "100%",
                                  marginBottom: "-8px",
                                }}
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-6">
                            <div className="setting-input">
                                <label
                                  htmlFor="exampleInputnumber"
                                  className="form-label"
                                >
                                  Tax Rate (Required)
                                </label>
                                <input
                                  type="number"
                                  step="any"
                                  {...register("merchant-tax-rate")}
                                  onChange={(e) => setMerchantTaxRate(e.target.value)}
                                  className="form-control"
                                  id="merchant-tax-rate"
                                  defaultValue={merchant.tax_rate}
                                />
                              </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputnumber"
                                className="form-label"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                {...register("merchant-phone")}
                                onChange={(e) => setMerchantPhone(e.target.value)}
                                className="form-control"
                                id="merchant-phone"
                                defaultValue={merchant.phone}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="form-label"
                              >
                                Update Password
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                {...register("merchant-password")}
                                defaultValue={merchant.password}
                                onChange={(e) => setMerchantPassword(e.target.value)}
                                className="form-control"
                                id="merchant-password"
                                placeholder="********"
                              />
                              <div className="form__group">
                              <input className="mt-3" type="checkbox" onChange={showPasswordToggle} /> Show Password
                            </div>
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Business Address
                              </label>
                              <input
                                type="text"
                                {...register("address")}
                                onChange={handleAddressChange}
                                className="form-control"
                                id="merchant-address"
                                defaultValue={merchant.address}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                {...register("city")}
                                onChange={handleAddressChange}
                                className="form-control"
                                id="merchant-city"
                                defaultValue={merchant.city}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                State
                              </label>
                              <SelectState
                                name={"state"}
                                defaultValue={merchant.state}
                                onChange={handleAddressChange}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Zip Code
                              </label>
                              <input
                                type="text"
                                {...register("zip")}
                                pattern="[0-9]{5}"
                                onChange={handleAddressChange}
                                className="form-control"
                                defaultValue={merchant.postal_code}
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary float-end w-50 btn-md"
                              disabled={maxCharacterLength}
                            >
                              Save
                            </button>
                            <p className="confirmation-message text-success fw-bold"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>

        <Tab.Container defaultActiveKey="Hours">
          <div className="col-xl-8">
            <Tab.Content>
              <Tab.Pane eventKey="Hours" key="hours">

                <form onSubmit={ handleSubmit(handleHoursSave) }>
                  <div className="setting-right">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="mb-4">Hours</h3>

                          <div className="row">
  
                            <h4 className="mb-4">Monday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-monday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    {...register("merchant-monday-open")}
                                    defaultValue={hours[0].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[0].open = option.value;
                                      setHours(newHours);
                                      setValue("merchant-monday-open", option.value);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-monday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                  <Select
                                  options={options}
                                  {...register("merchant-monday-close")}
                                  defaultValue={hours[0].close || null}
                                  onChange={option => {
                                    const newHours = [...hours];
                                    newHours[0].close = option.value;
                                    setHours(newHours);
                                    setValue("merchant-monday-close", option.value);
                                  }}
                                />

                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Tuesday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-tuesday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    {...register("merchant-tuesday-open")}
                                    defaultValue={hours[1].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[1].open = option.value;
                                      setHours(newHours);
                                      setValue("merchant-tuesday-open", option.value);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-tuesday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    
                                    defaultValue={hours[1].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[1].close = option.value;
                                      setHours(newHours);
                                      setValue("merchant-tuesday-close", option.value);
                                    }}

                                  />
                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Wednesday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-wednesday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    defaultValue={hours[2].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[2].open = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-wednesday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    defaultValue={hours[2].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[2].close = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Thursday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-thursday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    defaultValue={hours[3].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[3].open = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-thursday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    defaultValue={hours[3].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[3].close = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Friday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-friday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    defaultValue={hours[4].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[4].open = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-friday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    defaultValue={hours[4].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[4].close = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Saturday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-saturday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    defaultValue={hours[5].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[5].open = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-saturday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    defaultValue={hours[5].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[5].close = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                              </div>
                            </div>
                      
                          </div>

                          <div className="row">
  
                            <h4 className="mb-4">Sunday</h4>

                              <div className="col-xl-6 col-sm-6">
                                <div className="setting-input">
                                  <label
                                    htmlFor="merchant-sunday-open"
                                    className="form-label"
                                  >
                                  Open
                                  </label>
                                  <Select
                                    options={options}
                                    defaultValue={hours[6].open || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[6].open = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                </div>
                              </div>

                            <div className="col-xl-6 col-sm-6">
                              <div className="setting-input">
                                <label
                                  htmlFor="merchant-sunday-close"
                                  className="form-label"
                                >
                                  Close
                                </label>
                                <Select
                                    options={options}
                                    defaultValue={hours[6].close || null}
                                    onChange={option => {
                                      const newHours = [...hours];
                                      newHours[6].close = option.value;
                                      setHours(newHours);
                                    }}
                                  />
                                <button
                                  type="submit"
                                  className="btn btn-primary float-end w-50 btn-md mt-4"
                                  disabled={maxCharacterLength}
                                >
                                  Save
                                </button>
                                <p className="hours-confirmation-message text-success fw-bold mt-4"></p>
                                <p className="hours-error-message text-danger fw-bold mt-4"></p>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
              </Tab.Content>
          </div>
        </Tab.Container>

        <Tab.Container defaultActiveKey="Bank">
          <div className="col-xl-8">
            <Tab.Content>
              <Tab.Pane eventKey="Bank" key="bank">

                <form onSubmit={ handleSubmit(handleBankSave) }>
                  <div className="setting-right">
                    <div className="card">
                      <div className="card-body">
                      <h4 className="text-center mb-4 ">Bank Information</h4>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankAccountName(e.target.value)}
                          className="form-control"
                          placeholder="Example: My Checking Account"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Routing Number</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankAccountRouting(e.target.value)}
                          className="form-control"
                          placeholder="Enter Number"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account Number</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankAccountNumber(e.target.value)}
                          className="form-control"
                          placeholder="Enter Number"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account Type</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankAccountType(e.target.value)}
                          className="form-control"
                          placeholder="Example: Checking"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account First Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-control"
                          placeholder="Example: John"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account Last Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setLastName(e.target.value)}
                          className="form-control"
                          placeholder="Example: Doe"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Address</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankAddress(e.target.value)}
                          className="form-control"
                          placeholder="Example: Doe"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>City</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankCity(e.target.value)}
                          className="form-control"
                          placeholder="Example: Doe"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>State</strong>
                        </label>
                        <CustomStatesSelect setStateFunction={setBankState} />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Zip Code</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankPostalCode(e.target.value)}
                          className="form-control"
                          placeholder="Example: Doe"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Country</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankCountry(e.target.value)}
                          className="form-control"
                          placeholder="Example: Doe"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionName(e.target.value)}
                          className="form-control"
                          placeholder="Name of Bank"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution Address Line 1</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionAddress1(e.target.value)}
                          className="form-control"
                          placeholder="Enter Address of Bank"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution Address Line 2</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionAddress2(e.target.value)}
                          className="form-control"
                          placeholder="Suite, Unit, etc."
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution City</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionCity(e.target.value)}
                          className="form-control"
                          placeholder="Enter City of Bank"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Account State</strong>
                        </label>
                        <CustomStatesSelect setStateFunction={setBankInstitutionState} />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution Zip</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionPostalCode(parseInt(e.target.value))}
                          className="form-control"
                          placeholder="Enter Zip of Bank"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Bank Institution Country</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBankInstitutionCountry(e.target.value)}
                          className="form-control"
                          placeholder="Example: United States"
                        />
                        <button
                          type="submit"
                          className="btn btn-primary float-end w-25 btn-md mt-4"
                          disabled={maxCharacterLength}
                        >
                          Save
                        </button>
                        <p className="bank-confirmation-message text-success fw-bold mt-4"></p>
                        <p className="bank-error-message text-danger fw-bold mt-4"></p>
                      </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
              </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default MerchantSettings;
