import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import CustomStatesSelect from "../components/PluginsMenu/Select2/CustomStatesSelect";
import {
  MerchantSignupAction,
  loadingToggleAction,
} from "../../store/actions/AuthActions";
// image
import logo from "../../images/nuncmitto/nuncmitto-name.png";

function Register(props) {
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profile_image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [is_super_admin, setIsSuperAdmin] = useState(0);
  const [country, setCountry] = useState("United States");

  const [showPassword, setShowPassword] = useState(false);  


  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  let errorsObj = {
    businessName: "",
    email: "",
    password: "",
    taxRate: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    is_super_admin: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (businessName === "") {
      errorObj.businessName = "Username is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";

      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (phone === "") {
      errorObj.phone = "Phone is Required";
      error = true;
    }
    if (taxRate === "") {
      errorObj.taxRate = "Tax Rate is Required";
      error = true;
    }
    if (address === "") {
      errorObj.address = "Address is Required";
      error = true;
    }
    if (city === "") {
      errorObj.city = "City is Required";
      error = true;
    }
    if (state === "") {
      errorObj.state = "State is Required";
      error = true;
    }
    if (postalCode === "") {
      errorObj.postalCode = "Zip Code is Required";
      error = true;
    }
   
    dispatch(loadingToggleAction(true));
    dispatch(
      MerchantSignupAction(
        businessName,
        email,
        password,
        profile_image,
        phone,
        taxRate,
        address + ' ' + address2,
        city,
        state,
        postalCode,
        is_super_admin,
        navigate
      )
    );

  }

  function showPasswordToggle() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="authincation">
      <div className="container">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/merchant/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Create an account</h4>
                    {props.errorMessage && (
                      <div className="">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="">{props.successMessage}</div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Business Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setBusinessName(e.target.value)}
                          className="form-control"
                          placeholder="Business Name"
                        />
                      </div>
                      {errors.businessName && <div>{errors.businessName}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="Enter Email Address"
                        />
                      </div>
                      {errors.email && <div>{errors.email}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Password"
                        />
                        <input className="mt-3" type="checkbox" onChange={showPasswordToggle} /> Show Password
                      </div>
                      {errors.password && <div>{errors.password}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Phone</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setPhone(e.target.value)}
                          className="form-control"
                          placeholder="Phone Number"
                        />
                      </div>
                      {errors.phone && <div>{errors.phone}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Tax Rate (Example: 8.265)</strong>
                        </label>
                        <input
                          type="decimal"
                          onChange={(e) => setTaxRate(e.target.value)}
                          className="form-control"
                          placeholder="Tax Rate"
                        />
                      </div>
                      {errors.phone && <div>{errors.phone}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Address Line 1</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                          placeholder="Street Address"
                        />
                      </div>
                      {errors.address && <div>{errors.address}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Address Line 2</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setAddress2(e.target.value)}
                          className="form-control"
                          placeholder="Suite, Unit, etc."
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>City</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setCity(e.target.value)}
                          className="form-control"
                          placeholder="City"
                        />
                      </div>
                      {errors.city && <div>{errors.city}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>State</strong>
                        </label>
                        <CustomStatesSelect setStateFunction={setState} />
                      </div>
                      {errors.state && <div>{errors.state}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Zip Code</strong>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setPostalCode(e.target.value)}
                          className="form-control"
                          placeholder="Zip Code"
                        />
                      </div>
                      {errors.postalCode && <div>{errors.postalCode}</div>}

                     

                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary btn-block">
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/merchant/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
