import axiosInstance from "./AxiosInstance";

const url = {
  prod: 'https://api.dev.app.nuncmitto.com',
  dev: 'http://localhost:3000/',
};

const isDevEnv = process.env.NODE_ENV === 'development';

export async function getAllMerchants() {
  const res = await axiosInstance.get(`/merchant`);
  return res;
}

export async function getMerchant(merchantId) {
  const res = await axiosInstance.get(`/merchant/${merchantId}`);
  return res;
}

export async function updateMerchant(formData, Id) {
  const values = {};
  for (let [key, value] of formData.entries()) {
    values[key] = value;
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      'Access-Control-Allow-Origin': isDevEnv ? `${url.dev}` : `${url.prod}`,
    }
  }
  console.log("FORM DATA", formData);
  const res = await axiosInstance.post(`/merchant/${Id}`, formData, config);
  return res;
}

export async function updateGeoCode(address) {
  const postData = {
    address: address,
  }
  const res = await axiosInstance.get(`https://maps.googleapis.com/maps/api/geocode/json?${postData}`);
  return res;
}
