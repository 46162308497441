const isDevEnv = process.env.NODE_ENV === 'development';

// Media URL
// "https://api.nm.bdgwebdesign.com/storage/app/public"

const dev = process.env.REACT_APP_API_DEV_BASE_URL
const prod = process.env.REACT_APP_API_PROD_BASE_URL

const devMedia = process.env.REACT_APP_API_DEV_MEDIA_URL
const prodMedia = process.env.REACT_APP_API_PROD_MEDIA_URL

const API_CONFIG = {
  baseUrl: isDevEnv 
  ? dev
  : prod,
  mediaUrl: isDevEnv
  ? devMedia
  : prodMedia,
};

export default API_CONFIG;