import axiosInstance from "./AxiosInstance";

export function createAdvertising(formData) {
  return axiosInstance.post(`/advertising`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getAllAdvertising() {
  console.log("Service");
 return axiosInstance.get('/advertising');
}

export function getAdvertising(id) {
 return axiosInstance.get(`/advertising/${id}`);
}

export function updateAdvertising(id) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }

  return axiosInstance.put(`/advertising/${id}`, config);
}

export function deleteAdvertising(id) {
  console.log(`Deleting advertising with id: ${id}`);
  return axiosInstance.delete(`/advertising/${id}`)
    .then(response => {
      console.log('Delete successful', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Delete failed', error);
      throw error;
    });
}