import { ADD_MENU_ITEM_SPECIAL_ACTION } from "../actions/MenuItemsActions";
import { ADD_MENU_ITEM_ACTION } from "../actions/MenuItemsActions";
import { GET_MENU_ITEM_ACTION } from "../actions/MenuItemsActions";
import { GET_MERCHANT_MENU_ITEM_ACTION } from "../actions/MenuItemsActions";
import { UPDATE_MENU_ITEM_ACTION } from "../actions/MenuItemsActions";
import { DELETE_MENU_ITEM_ACTION } from "../actions/MenuItemsActions";
import { DELETE_MENU_ITEM_SPECIAL_ACTION } from "../actions/MenuItemsActions";

const initialState = {
  menuItems: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function menuItems(state = initialState, action) {
  if (action.type === ADD_MENU_ITEM_ACTION) {
    return {
      ...state,
      menuItems: action.payload,
      errorMessage: "Menu Item Create Unsuccessful",
      successMessage: "Menu Item Created Successfully",
      showLoading: false,
    };
  }

  if (action.type === GET_MENU_ITEM_ACTION) {
    return {
      ...state,
      menuItems: action.payload,
      errorMessage: "Menu Item Retrieval Unsuccessful",
      successMessage: "Menu Item Successfully Retrieved",
      showLoading: false,
    };
  }

  if (action.type === GET_MERCHANT_MENU_ITEM_ACTION) {
    return {
      ...state,
      menuItems: action.payload,
      errorMessage: "Menu Item Retrieval Unsuccessful",
      successMessage: "Menu Item Successfully Retrieved",
      showLoading: false,
    };
  }

  if (action.type === UPDATE_MENU_ITEM_ACTION) {
    return {
      ...state,
      menuItems: action.payload,
      errorMessage: "Menu Item Update Unsuccessful",
      successMessage: "Menu Item Update Successful",
      showLoading: false,
    };
  }

  if (action.type === DELETE_MENU_ITEM_ACTION) {
    return {
      ...state,
      items: action.payload,
      errorMessage: "Menu Item Delete Unsuccessful",
      successMessage: "Menu Item Successfully Deleted",
      showLoading: false,
    };
  }

  if (action.type === DELETE_MENU_ITEM_SPECIAL_ACTION) {
    return {
      ...state,
      items: action.payload,
      errorMessage: "Menu Item Delete Unsuccessful",
      successMessage: "Menu Item Successfully Deleted",
      showLoading: false,
    };
  }

  return state;
}
