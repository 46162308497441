import { Link } from "react-scroll";
import { Tab, Nav } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function OrdersSummary(props) {
  const { allOrders, activeOrders, redeemedOrders, totalPrice, expiredOrders, handleDate, clickHandler } = props;

  return(
    <div className="col-xl-12">
    <div className="card">
      <Tab.Container defaultActiveKey="All">
        <div className="card-body">
          {/** Nav is for the tabs for the orders section */}
          <nav className="order-tab">
            <Nav className="nav-tabs">
              <Nav.Link eventKey="All" id="nav-order-tab">
                All
              </Nav.Link>
              <Nav.Link eventKey="Active" id="nav-order-tab">
                Active
              </Nav.Link>
              <Nav.Link eventKey="Redeemed" id="nav-redeemed-tab">
                Redeemed
              </Nav.Link>
              <Nav.Link eventKey="Expired" id="nav-expired-tab">
                Expired
              </Nav.Link>
            </Nav>
          </nav>

          <Tab.Content>
            <Tab.Pane eventKey="All">
              {allOrders.map((item, index) => (
                <div
                  className="orderin-bx d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <div>
                    <h4>Order #{item.id}</h4>
                    <span>{handleDate(item.created_at)}</span>
                  </div>
                  <div className="d-flex align-items-center">
                  <h4 className="text-primary mb-0">${item.price}</h4>
                    <Link
                      to={"order-details"}
                      smooth={false}
                      className="icon-bx"
                      onClick={() => clickHandler(item)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </Tab.Pane>

            <Tab.Pane eventKey="Active">
              {activeOrders && activeOrders.map((item, index) => (
                <div
                  className="orderin-bx d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <div>
                    <h4>Order #{item.id}</h4>
                    <span>{handleDate(item.created_at)}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <h4 className="text-primary mb-0">${item.price}</h4>
                    <Link
                      to={"order-details"}
                      smooth={false}
                      className="icon-bx"
                      onClick={() => clickHandler(item)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </Tab.Pane>

            <Tab.Pane eventKey="Redeemed">
              {redeemedOrders && redeemedOrders.map((item, index) => (
                <div
                  className="orderin-bx d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <div>
                    <h4>Order #{item.id}</h4>
                    <span>{handleDate(item.created_at)}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <h4 className="text-primary mb-0">${item.price}</h4>
                    <Link
                      to={"order-details"}
                      smooth={false}
                      className="icon-bx"
                      onClick={() => clickHandler(item)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </Tab.Pane>

            <Tab.Pane eventKey="Expired">
              {expiredOrders && expiredOrders.map((item, index) => (
                <div
                  className="orderin-bx d-flex align-items-center justify-content-between"
                  key={index}
                >
                  <div>
                    <h4>Order #{item.id}</h4>
                    <span>{`Expired: ${item.expiration_date}`}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <h4 className="text-primary mb-0">${item.price}</h4>
                    <Link to={"#"} className="icon-bx">
                      <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  </div>
  );
}