import React, { lazy, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { GetUserActions } from "../../../../store/actions/UserActions";

/// images
import avartar5 from "../../../../images/avatar/5.jpg";
import avartar1 from "../../../../images/avatar/1.jpg";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";

const Header = lazy(() => import("../../../layouts/nav/Header"));
const NavHeader = lazy(() => import("../../../layouts/nav/NavHeader"));
const SideBar = lazy(() => import("../../../layouts/nav/SideBar"));

const Customers = () => {
  const orders = useSelector((state) => state.orders.orders.data);
  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const users = useSelector((state) => state.users.users.data);
  const state = useSelector((state) => state);

  const [customerIds, setCustomerIds] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filtered, setFiltered] = useState(false);

  const dispatch = useDispatch();

  // Filter orders for merchant
  useEffect(() => {
    if (orders && Array.isArray(orders)) {
      setOrderList(orders.filter((order) => order.merchant_id === merchantId));
    }
  }, []);

  // Get each unique user ID for each order
  useEffect(() => {
    if (orderList && orderList !== undefined) {
      const uniqueIdList = new Set();

      orderList.forEach((order) => {
        uniqueIdList.add(order.user_id);
      });
      const IdArray = [...uniqueIdList];
      setCustomerIds(IdArray);
    }
  }, [orderList]);

  // Use the user Id to get user's data
  useEffect(() => {
    if (customerIds && customerIds !== undefined) {
      customerIds.forEach((id) => {
        dispatch(GetUserActions(id));
      });
    }
  }, [customerIds]);

  // Create array of users with their user data
  useEffect(() => {
    if (users && users !== undefined) {
      const uniqueCustomers = new Set();
      uniqueCustomers.add(users);
      const customerArray = [...uniqueCustomers];
      setCustomers(customerArray);
    }
  }, [users]);

  // Get search input value and filter customer array and set new array with filtered results
  function handleSearch(e) {
    if (e.target.value !== '' || e.target.value !== null) {
      setFilteredUsers(customers.filter((user) => user.name.includes(e.target.value)));
      setFiltered(true);
    } else {
      setFilteredUsers([]);
      setFiltered(false);
    }
  }

  return (
    <>
      <div className="row">
        <PageTitle />
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="input-group search-area2 style-1">
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Search customers..."
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      {/* <th className="ps-5 width200">Billing Address</th> */}
                    </tr>
                  </thead>
                  <tbody id="customers">
                    {filtered ? filteredUsers.map((customer, index) => (
                      <tr key={index} className="btn-reveal-trigger">
                        <td className="py-3">
                          <Link to="/ecom-customers">
                            <div className="media d-flex align-items-center">
                              <div className="avatar avatar-xl me-2">
                                <div className="">
                                  <img
                                    className="rounded-circle img-fluid"
                                    src={avartar5}
                                    width="30"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="mb-0 fs--1">{customer.name}</h5>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className="py-2">
                          <a href="mailto:ricky@example.com">{customer.email}</a>
                        </td>
                        <td className="py-2">
                          {" "}
                          <a href="tel:2012001851">(201) 200-1851</a>
                        </td>
                        {/* <td className="py-2 ps-5 wspace-no">
                          2392 Main Avenue, Penasauka
                        </td> */}
                        {/* <td className="py-2">30/03/2018</td> */}
                        {/* <td className="py-2 text-right">{drop}</td> */}
                      </tr>
                    )):
                    customers && customers.map((customer, index) => (
                      <tr key={index} className="btn-reveal-trigger">
                        <td className="py-3">
                          <Link to="/ecom-customers">
                            <div className="media d-flex align-items-center">
                              <div className="avatar avatar-xl me-2">
                                <div className="">
                                  <img
                                    className="rounded-circle img-fluid"
                                    src={avartar5}
                                    width="30"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 className="mb-0 fs--1">{customer.name}</h5>
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className="py-2">
                          <a href="mailto:ricky@example.com">{customer.email}</a>
                        </td>
                        <td className="py-2">
                          {" "}
                          <a href="tel:2012001851">(201) 200-1851</a>
                        </td>
                        {/* <td className="py-2 ps-5 wspace-no">
                          2392 Main Avenue, Penasauka
                        </td> */}
                        {/* <td className="py-2">30/03/2018</td> */}
                        {/* <td className="py-2 text-right">{drop}</td> */}
                      </tr>
                    ))
                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
