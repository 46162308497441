import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import SideBar from "../../../layouts/nav/SideBar";
import Header from "../../../layouts/nav/Header";
import NavHeader from "../../../layouts/nav/NavHeader";

import StatisticChart from "./StatisticChart";
import Pic6 from "./../../../../images/chat-img/pic-6.jpg";

import {
  DoubleRight,
  CircleRight,
  CircleExclamation,
  EarningIcon,
  PointIcon,
  TimerIcon,
  MinGrapg,
  AvgGraph,
} from "./SvgIcons";

const statusBlog = [
  { icons: DoubleRight, title: "Finished", number: "932" },
  { icons: CircleRight, title: "Delivered", number: "1,032" },
  { icons: CircleExclamation, title: "Canceled", number: "102" },
];

const EarningBlog = [
  { number: "11.240", IconClass: "success" },
  { number: "10.654", IconClass: "primary" },
];

const performance = [
  { iconblog: TimerIcon, title: "Performance", percent: "50%" },
  { iconblog: MinGrapg, title: "Min. Performance", percent: "80%" },
  { iconblog: AvgGraph, title: "Avg. Performance", percent: "75%" },
];

const DeliverMain = () => {
  const [dropValue, setDropValue] = useState("This Month");
  return (
    <>
      <Header />
      <NavHeader />
      <SideBar />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="cate-title">Profile</h4>
                </div>
                <div className="card-body pt-2">
                  <div className="d-profile">
                    <div className="d-flex justify-content-between mb-3 mb-sm-0">
                      <div className="d-flex">
                        <img src={Pic6} alt="" />
                        <div>
                          <h3 className="font-w700">Merchant Name</h3>
                          <div className="d-flex align-items-center">
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 0.500031L9.79611 6.02789H15.6085L10.9062 9.4443L12.7023 14.9722L8 11.5558L3.29772 14.9722L5.09383 9.4443L0.391548 6.02789H6.20389L8 0.500031Z"
                                fill="#FC8019"
                              ></path>
                            </svg>
                            <p className="mb-0 px-2">5.0</p>
                            <svg
                              className="me-2"
                              width="4"
                              height="5"
                              viewBox="0 0 4 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="2"
                                cy="2.50003"
                                r="2"
                                fill="#C4C4C4"
                              ></circle>
                            </svg>
                            <p className="mb-0">1k+ Reviews</p>
                          </div>
                          <p>Join June 2020</p>
                          <Link
                            to={"edit-profile"}
                            className="btn btn-light btn-sm"
                          >
                            Edit Profile
                          </Link>
                        </div>
                      </div>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="div"
                            className="btn-link i-false"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                stroke="#262626"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                stroke="#262626"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                stroke="#262626"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dropdown-menu m-0"
                            align="end"
                          >
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="card h-auto mb-3">
                    <div className="card-body p-3">
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          <div className="icon-bx d-inline-block text-center bg-info me-sm-4 me-2">
                            {PointIcon}
                          </div>
                        </div>
                        <div className="d-flex">
                          <p className="mb-0">merchant@email.com</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card h-auto mb-3">
                    <div className="card-body p-3">
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          <div className="icon-bx d-inline-block text-center bg-info me-sm-4 me-2">
                            {PointIcon}
                          </div>
                        </div>
                        <div className="d-flex">
                          <p className="mb-0">555-555-5555</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="setting-input">
                    <p className="fs-18 mb-2">Location</p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219"
                      style={{
                        border: "0",
                        width: "100%",
                        minHeight: "100%",
                        marginBottom: "-8px",
                      }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliverMain;
