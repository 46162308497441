import React from "react";
import {
  GET_ORDERS_ACTION,
  GET_SINGLE_ORDER_ACTION,
  GET_MERCHANT_ORDERS_ACTION,
  DELETE_ORDER_ACTION,
} from "../actions/OrdersActions";

const initialState = {
  orders: {},
  singleOrders: {},
  merchantOrders: {},
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function orders(state = initialState, action) {
  if (action.type === GET_ORDERS_ACTION) {
    return {
      ...state,
      orders: action.payload,
      errorMessage: "Menu Item Create Unsuccessful",
      successMessage: "Menu Item Created Successfully",
      showLoading: false,
    };
  }

  if (action.type === GET_SINGLE_ORDER_ACTION) {
    return {
      ...state,
      singleOrders: action.payload,
      errorMessage: "Menu Item Create Unsuccessful",
      successMessage: "Menu Item Created Successfully",
      showLoading: false,
    };
  }

  if (action.type === GET_MERCHANT_ORDERS_ACTION) {
    return {
      ...state,
      merchantOrders: action.payload,
      errorMessage: "Menu Item Create Unsuccessful",
      successMessage: "Menu Item Created Successfully",
      showLoading: false,
    }
  }

  if (action.type === DELETE_ORDER_ACTION) {
    return {
      ...state,
      orders: action.payload,
      errorMessage: "Menu Item Create Unsuccessful",
      successMessage: "Menu Item Created Successfully",
      showLoading: false,
    };
  }

  return state;
}
