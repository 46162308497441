import { GET_MERCHANT_ACTION } from "../actions/MerchantActions";
import { GET_ALL_MERCHANTS_ACTION } from "../actions/MerchantActions";  
import { UPDATE_MERCHANT_ACTION } from "../actions/MerchantActions";
import { UPDATE_GEO_CODE_ACTION } from "../actions/MerchantActions";
import { MERCHANT_CREATE_ACTION } from "../actions/MerchantActions";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function merchant(state = initialState, action) {
  switch (action.type) {
    case MERCHANT_CREATE_ACTION:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Merchant Creation Failed"
          : "Merchant Created Successfully",
        successMessage: action.payload.error ? "" : "Merchant Created Successfully",
        showLoading: false,
      };
    case GET_MERCHANT_ACTION:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Merchant Data Retrieval Failed"
          : "Merchant Data Successfully Retrieved",
        successMessage: action.payload.error ? "" : "Merchant Data Successfully Retrieved",
        showLoading: false,
      };
    case GET_ALL_MERCHANTS_ACTION:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Merchant Data Retrieval Failed"
          : "Merchant Data Successfully Retrieved",
        successMessage: action.payload.error ? "" : "Merchant Data Successfully Retrieved",
        showLoading: false,
      };
    case UPDATE_MERCHANT_ACTION:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Merchant Update Failed"
          : "Merchant Data Successfully Updated",
        successMessage: action.payload.error ? "" : "Merchant Data Successfully Updated",
        showLoading: false,
      };
    default:
      return state;
  }
}
