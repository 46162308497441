import React from "react";
import { Row, Card, Col, Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API_CONFIG from "../../../../apiConfig";
import bgimage from "../../../../images/no-img-avatar.png";

const Merchants = ({ merchant }) => {
    const mediaUrl = API_CONFIG.mediaUrl;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/merchant-settings-admin', { state: { merchant } });
    }

    return (
        
        <Col sm="2">

            <Card>
                <div className="card-img-container">
                    <img
                        className="card-img-top card-img-cover img-fluid"
                        src={merchant.profile_image ? `${mediaUrl}/${merchant.profile_image}` : bgimage}  
                        alt="Card cap"
                    />
                </div>
                <Card.Header className="border-0 pb-0">
                    <Card.Title>{merchant.business_name}</Card.Title>
                </Card.Header>
                {/* <Card.Body>
                    { <Card.Text>
                        He lay on his armour-like back, and if he lifted his head a
                        little he could see his brown belly, slightly domed and divided
                        by arches into stiff <br /> sections. The bedding was hardly
                        able to cover it and seemed ready to slide off any moment.
                    </Card.Text> }
                </Card.Body> */}
                <Card.Footer className="border-0 pt-0">
                    {/* <Card.Text className="d-inline">Card footer</Card.Text> */}
                    <Card.Link className="float-end cursor-pointer" onClick={handleClick}>
                        Edit
                    </Card.Link>
                </Card.Footer>
            </Card>
        </Col>
    );
}

export default Merchants;