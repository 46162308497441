import axios from "axios";
import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

export async function signUp(name, email, password) {
  //axios call
  const postData = {
    name,
    email,
    password,
    returnSecureToken: true,
  };
  try {
    const res = await axiosInstance.post('/signup', postData);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function merchantSignup(
  business_name,
  email,
  password,
  profile_image,
  phone,
  tax_rate,
  address,
  city,
  state,
  postal_code,
  is_super_admin
) {
  const postData = {
    business_name,
    email,
    password,
    profile_image,
    phone,
    tax_rate,
    address,
    city,
    state,
    postal_code,
    is_super_admin,
    returnSecureToken: true,
  };
  try {
    const res = await axiosInstance.post('/merchant/signup', postData);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.post('/merchant/login', postData, { withCredentials: true });
}

// export function superAdminLogin(email, password) {
//   const postData = {
//     email,
//     password,
//   };
//   return axiosInstance.post('/super-admin/login', postData, { withCredentials: true });
// }

export function formatError(errorResponse) {
  console.log("error response", errorResponse.response);
  // switch (errorResponse.error.message) {
  switch (errorResponse.response) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "No query results for model [App\\Models\\Merchant].":
      //return 'Email not found';
      swal("Email not found", "error", { button: "Try Again!" });
      break;
    case "The provided credentials are incorrect.":
      swal("Your Email Address or Password was Incorrect", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  if (tokenDetails) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    axios.defaults.headers.common.Authorization = `Bearer ${tokenDetails}`;
  } else {
    localStorage.removeItem('userDetails');
    delete axios.defaults.headers.common.Authorization;
  }
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}

// export function LoginAfterSignUp(email, password) {
//   useEffect(() => {
//     const headers = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const login = {
//       email: email,
//       password: password,
//     };

//     axios
//       .post(
//       `https://api.nm.bdgwebdesign.com/public/api/v1/user/login`,
//         login,
//         headers
//       )
//       .then((res) => {
//         console.log(res.data);
//       })
//       .catch((error) => {
//         console.error(error);
//         if (error.response) {
//           console.log(error.response.data);
//         }
//       });
//   }, []);
// }
