import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import OrdersSummary from "./OrdersSummary";
import API_CONFIG from "../../../../apiConfig";

import pic1 from "./../../../../images/chat-img/orders-img/pic-1.jpg";
import {
  GetMerchantOrdersAction,
  GetSingleOrderAction,
  DeleteOrderAction,
} from "../../../../store/actions/OrdersActions";
import OrdersDetails from "./OrdersDetails";
import { Link } from "react-router-dom";
import { set } from "react-hook-form";

const Orders = (props) => {
  const { isDashboard } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Store data
  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const orders = useSelector((state) => state.orders.merchantOrders);
  const orderItems = useSelector((state) => {
    const singleOrderData = state.orders && state.orders.singleOrders ? state.orders.singleOrders.data : "";
  
    if (singleOrderData && singleOrderData.order_items) {
      return singleOrderData.order_items;
    } else {
      return [];
    }
  });

  // Component State
  const [allOrders, setAllOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [redeemedOrders, setRedeemedOrders] = useState([]);
  const [expiredOrders, setExpiredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({
    id: "",
    user_id: "UserName",
  });
  const [selected, setSelected] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [totalPrice, setOrderTotalPrice] = useState(0.0);
  const [loadingItems, setLoadingItems] = useState(true);

  // Use Effect
  useEffect(() => {
    if (merchantId) {
      dispatch(GetMerchantOrdersAction(merchantId));
    }
  }, [merchantId]);

  // Filter to get only completed orders, then filter the completedOrders to get active, redeemed, and expired orders
  useEffect(() => {
    if (orders && orders.length > 0) {
      const completedOrders = orders.filter((order) => order.is_completed === 1);
      setAllOrders(completedOrders);
      setActiveOrders(completedOrders.filter((order) => order.is_redeemed === 0 && checkIfExpired(order) === false));
      setRedeemedOrders(completedOrders.filter((order) => order.is_redeemed === 1));
      setExpiredOrders(completedOrders.filter((order) => checkIfExpired(order) === true));
    }

  }, [merchantId, orders]);

  useEffect(() => {
    if (selectedOrder.id !== "") {
      dispatch(GetSingleOrderAction(selectedOrder.id));
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      setOrderDetails(orderItems);
      setLoadingItems(false);
    }
  }, [orderItems]);

  useEffect(() => {
    setLoadingItems(false);
    getTotalPrice();
  }, [orderDetails]);

  function checkIfExpired(order) {
    const today = new Date();
    const expirationDate = new Date(order.expiration_date).getTime();

    const daysLeft = expirationDate - today;
    const daysDiff = Math.ceil(daysLeft / (1000 * 60 * 60 * 24));

    const isExpired = daysDiff < 0;
    
    return isExpired;
  }

  // Functions
  function getTotalPrice() {
    let price = 0;
    if (orderDetails) {
      orderDetails.forEach((order) => {
        price += parseFloat(order.item.price);
      });
      price = price.toFixed(2);
      price = parseFloat(price);
      return setOrderTotalPrice(price.toString());
    }
  }

  function clickHandler(order) {
    if (isDashboard === false) {
      setSelectedOrder(order);
      setSelected(true);
    } else {
      navigate('/orders');
    }
  }

  function handleDate(date) {
    let timestamp = date;
    let newDate = new Date(timestamp);
    let formattedDate = newDate.toLocaleString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedDate;
  }

  return (
    <>
      <div className="row">
        <PageTitle />
        <OrdersSummary allOrders={allOrders} activeOrders={activeOrders} redeemedOrders={redeemedOrders} expiredOrders={expiredOrders} totalPrice={totalPrice} handleDate={handleDate} clickHandler={clickHandler} isDashboard={isDashboard}/>

        {isDashboard === false && orderDetails !== null && loadingItems === false && selected === true ? (
          <OrdersDetails selectedOrder={selectedOrder} pic1={pic1} orderDetails={orderDetails} totalPrice={totalPrice} formatDate={handleDate}/>
        ) : null}
      </div>
    </>
  );
};

export default Orders;
