import { CREATE_BANK_ACCOUNT } from "../actions/BankAccountsActions";
import { GET_ALL_BANK_ACCOUNTS } from "../actions/BankAccountsActions";
import { GET_BANK_ACCOUNT } from "../actions/BankAccountsActions";
import { UPDATE_BANK_ACCOUNT } from "../actions/BankAccountsActions";
import { DELETE_BANK_ACCOUNT } from "../actions/BankAccountsActions";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function bankAccounts(state = initialState, action) {
  switch (action.type) {
    case CREATE_BANK_ACCOUNT:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Bank Account Creation Failed"
          : "Bank Account Created Successfully",
        successMessage: action.payload.error ? "" : "Bank Account Created Successfully",
        showLoading: false,
      };
    case GET_ALL_BANK_ACCOUNTS:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Bank Account Data Retrieval Failed"
          : "Bank Account Data Successfully Retrieved",
        successMessage: action.payload.error ? "" : "Bank Account Data Successfully Retrieved",
        showLoading: false,
      };
    case GET_BANK_ACCOUNT:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Bank Account Data Retrieval Failed"
          : "Bank Account Data Successfully Retrieved",
        successMessage: action.payload.error ? "" : "Bank Account Data Successfully Retrieved",
        showLoading: false,
      };
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Bank Account Update Failed"
          : "Bank Account Data Successfully Updated",
        successMessage: action.payload.error ? "" : "Bank Account Data Successfully Updated",
        showLoading: false,
      };

    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: action.payload.error
          ? "Bank Account Deletion Failed"
          : "Bank Account Data Successfully Deleted",
        successMessage: action.payload.error ? "" : "Bank Account Data Successfully Deleted",
        showLoading: false,
      };
    default:
      return state;
  }
}
