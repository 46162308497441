export const MenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-grid",
    content: [
      {
        title: "Dashboard",
        to: "/dashboard",
      },
      // {
      //   title: "Dashboard Settings",
      //   to: "/dashboard-settings",
      // },
    ],
  },
  {
    title: "Merchant",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop",
    content: [
      // {
      //   title: "Merchant Dashboard",
      //   to: "/merchant-dashboard",
      // },
      {
        title: "Merchant Profile",
        to: "/merchant-profile",
      },
      {
        title: "Menu Items",
        to: "/merchant-menu",
      },
      {
        title: "Merchant Settings",
        to: "/merchant-settings",
      },
    ],
  },
  {
    title: "Orders",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-bag-fill",
    content: [
      {
        title: "Orders",
        to: "/orders",
      },
    ],
  },
  {
    title: "Customers",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Customers",
        to: "/customers",
      },
    ],
  },
  // {
  //   title: "Settings",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-gear-wide",
  //   content: [
  //     {
  //       title: "Settings",
  //       to: "/settings",
  //     },
  //   ],
  // },
  // {
  //   title: "Super Admin",
  //   classsChange: "mm-collapse",
  //   // iconStyle : "bi bi-heart",
  //   iconStyle: "bi bi-star",
  //   content: [
  //     {
  //       title: "Admin",
  //       to: "/admin",
  //     },
  //   ],
  // },
];
