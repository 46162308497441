import React from "react";
import { addMenuItem, updateMerchant } from "../../services/MerchantService";
import { getMerchant, getAllMerchants } from "../../services/MerchantService";
import { formatError } from "../../services/AuthService";

export const MERCHANT_CREATE_ACTION = "MERCHANT_CREATE_ACTION";
export const GET_MERCHANT_ACTION = "GET_MERCHANT_ACTION";
export const GET_ALL_MERCHANTS_ACTION = "GET_ALL_MERCHANTS_ACTION";
export const UPDATE_MERCHANT_ACTION = "UPDATE_MERCHANT_ACTION";
export const UPDATE_GEO_CODE_ACTION = "UPDATE_GEO_CODE_ACTION";
export const DELETE_MERCHANT_ACTION = "DELETE_MERCHANT_ACTION";
export const ADD_MENU_ITEM_ACTION = "ADD_MENU_ITEM_ACTION";

export function GetMerchantDataAction(merchandId) {
  return (dispatch) => {
    getMerchant(merchandId)
      .then((response) => {
        dispatch(GetMerchantConfirmedAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function GetAllMerchantsAction() {
  return (dispatch) => {
    getAllMerchants()
      .then((response) => {
        dispatch(GetAllMerchantsConfirmedAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function UpdateMerchantAction(formData, Id) {
  return async (dispatch) => {
    try {
      const response = await updateMerchant(formData, Id);
      dispatch(UpdateMerchantConfirmedAction(response.data));
    } catch (error) {
      const errorMessage = formatError(error.message);
      console.log(errorMessage);
    }
  };
}

export function updateGeoCode(address) {
  return (dispatch) => {
    updateGeoCode(address)
      .then((response) => {
        dispatch(UpdateGeoCodeAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  }
}

export function CreateMerchantConfirmedAction(data) {
  return {
    type: MERCHANT_CREATE_ACTION,
    payload: data,
  };
}

export function GetMerchantConfirmedAction(data) {
  return {
    type: GET_MERCHANT_ACTION,
    payload: data,
  };
}

export function GetAllMerchantsConfirmedAction(data) {
  return {
    type: GET_ALL_MERCHANTS_ACTION,
    payload: data,
  };
}

export function UpdateMerchantConfirmedAction(data) {
  return {
    type: UPDATE_MERCHANT_ACTION,
    payload: data,
  };
}

export function UpdateGeoCodeAction(data) {
  return {
    type: UPDATE_GEO_CODE_ACTION,
    payload: data,
  };
}

export function AddMenuItemAction(data) {
  return {
    type: ADD_MENU_ITEM_ACTION,
    payload: data,
  };
}
