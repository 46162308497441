import React from "react";
import { getUser, getAllUsers } from "../../services/UserService";
import { formatError } from "../../services/AuthService";

export const GET_ALL_USERS_CONFIRMED_ACTION =
  "[Get users action] retrieved all users successfully";
export const GET_USER_CONFIRMED_ACTION =
  "[Get user action] retrieved user successfully";

export function GetAllUsersActions() {
  return (dispatch) => {
    getAllUsers()
      .then((response) => {
        dispatch(GetAllUsersConfirmedAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function GetUserActions(Id) {
  return (dispatch) => {
    getUser(Id)
      .then((response) => {
        dispatch(GetUserConfirmedAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function GetAllUsersConfirmedAction(data) {
  return {
    type: GET_ALL_USERS_CONFIRMED_ACTION,
    payload: data,
  };
}

export function GetUserConfirmedAction(data) {
  return {
    type: GET_USER_CONFIRMED_ACTION,
    payload: data,
  };
}
