import React, { lazy, useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { AddMenuItemSpecialsAction, AddMenuItemsAction } from "../../../../store/actions/MenuItemsActions";
import pic3 from "../../../../images/popular-img/pic-3.jpg";
import noFoodImage from "../../../../images/food-placeholder.png";
import API_CONFIG from "../../../../apiConfig";

function AddItemModal(props) {
  const { modalToggle, showModal, addedItem, isSpecial } = props;

  const merchantId = useSelector((state) => state.auth.auth.merchant_id);

  const dispatch = useDispatch();

  const [menuItemImage, setMenuItemImage] = useState("");
  const [menuItemImagePreview, setmenuItemImagePreview] = useState(noFoodImage);
  const [menuItemTitle, setMenuItemTitle] = useState("");
  const [menuItemDescription, setMenuItemDescription] = useState("");
  const [menuItemPrice, setMenuItemPrice] = useState(null);
  const [menuItemActive, setMenuItemActive] = useState("1");

  const fileHandler = (e) => {
    const value = e.target.files[0];
    setMenuItemImage(value);

    // Preview image
    const blob = URL.createObjectURL(value);
    setmenuItemImagePreview(blob);
  };

  function RemoveImage() {
    return setmenuItemImagePreview(null);
  }

  function handleClose() {
    RemoveImage();
    modalToggle();
  }

  function handleSave(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("merchant_id", merchantId);
    formData.append("title", menuItemTitle);
    formData.append("description", menuItemDescription);
    formData.append("price", menuItemPrice);
    formData.append("image", menuItemImage);
    formData.append("active", menuItemActive);
    formData.append("special", isSpecial);
    dispatch(AddMenuItemsAction(formData));
    // isSpecial ? dispatch(AddMenuItemSpecialsAction(formData)) : console.log("Not Special", isSpecial);
    addedItem(true);
    RemoveImage();
    modalToggle();
  }

  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => dispatch({ type: "addNewItem" })}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add Menu Item
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => handleClose()}
        ></button>
      </div>

      <div className="modal-body">
        <form onSubmit={handleSave}>
          {/* <div className="d-flex align-items-center active justify-content-center">
            <div className="form-check me-3 ">
              <input
                className="form-check-input"
                type="radio"
                name="active"
                id="is-active"
                value={"1"}
                onChange={(e) => setMenuItemActive(e.target.value)}
                checked={menuItemActive === "1"}
              />
              <label className="form-check-label" htmlFor="is-active">
                Active
              </label>
            </div>
            <div className="form-check style-1">
              <input
                className="form-check-input"
                type="radio"
                name="active"
                id="is-inactive"
                value={"0"}
                onChange={(e) => setMenuItemActive(e.target.value)}
                checked={menuItemActive === "0"}
              />
              <label className="form-check-label" htmlFor="is-inactive">
                Inactive
              </label>
            </div>
          </div> */}

          <div className="modal-inside">
            <p className="fs-18">Item Image</p>
            <div className="setting-img d-flex align-items-center mb-4">
              <div className="avatar-upload d-flex align-items-center">
                <div className=" change position-relative d-flex">
                  <div className="avatar-preview">
                    <img
                      className="menu-item-image-file"
                      src={menuItemImagePreview ? menuItemImagePreview : noFoodImage}
                      alt={menuItemImage ? menuItemImage.name : "Item Image Preview"}
                    />
                  </div>
                  <div className="change-btn d-flex align-items-center flex-wrap">
                    <input
                      type="file"
                      name="image"
                      onChange={fileHandler}
                      className="form-control"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                    />
                    <label htmlFor="imageUpload" className="dlab-upload">
                      Choose File
                    </label>
                    <button
                      type="button"
                      className="btn remove-img ms-2"
                      onClick={() => RemoveImage()}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <label htmlFor="exampleInputText" className="form-label">
              Item Name
            </label>
            <input
              type="text"
              name="item-name"
              onChange={(e) => setMenuItemTitle(e.target.value)}
              className="form-control"
              id="exampleInputText"
              placeholder=""
            />
          </div>
          <div className="modal-inside">
            <label htmlFor="exampleInputText" className="form-label">
              Item Description
            </label>
            <textarea
              rows={5}
              cols={20}
              name="item-description"
              onChange={(e) => setMenuItemDescription(e.target.value)}
              className="form-control h-25"
              id="exampleInputText"
              placeholder=""
            />
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="modal-inside">
                <label htmlFor="exampleInputnumber" className="form-label mb-2">
                  Item Price
                </label>
                <input
                  type="number"
                  step="0.01"
                  name="item-price"
                  onChange={(e) => setMenuItemPrice(e.target.value)}
                  className="form-control"
                  id="exampleInputnumber"
                />
              </div>
            </div>
            <div className="col-xl-4">
              {/* <div className="modal-inside">
                  <label htmlFor="exampleInputnumber-1" className="form-label mb-2">
                    Delivery Charge
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputnumber-1"
                  />
                </div> */}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handleClose()}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save changes
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddItemModal;
