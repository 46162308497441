import React from "react";
import {
  createBankAccount,
  getAllBankAccounts,
  getBankAccount,
  updateBankAccount,
  deleteBankAccount,
} from "../../services/BankAccountsService";
import { formatError } from "../../services/AuthService";
import { merchant } from "../reducers/MerchantReducer";

export const CREATE_BANK_ACCOUNT = "CREATE_BANK_ACCOUNT"; 
export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const GET_ALL_BANK_ACCOUNTS = "GET_ALL_BANK_ACCOUNTS";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";

export function CreateBankAccount(
  merchant_id,
  bank_name,
  routing_number,
  account_number,
  account_type,
  is_allow_wire,
  first_name,
  last_name,
  address,
  city,
  state,
  zip,
  country,
  banking_institution_name,
  bank_institution_address,
  bank_institution_city,
  bank_institution_state,
  bank_institution_postal_code,
  bank_institution_country,
) {
  return (dispatch) => {
    createBankAccount(
      merchant_id,
      bank_name,
      routing_number,
      account_number,
      account_type,
      is_allow_wire,
      first_name,
      last_name,
      address,
      city,
      state,
      zip,
      country,
      banking_institution_name,
      bank_institution_address,
      bank_institution_city,
      bank_institution_state,
      bank_institution_postal_code,
      bank_institution_country,
    )
      .then((response) => {
        dispatch(CreateBankAccountAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function GetAllBankAccounts() {
  return (dispatch) => {
    getAllBankAccounts()
      .then((response) => {
        dispatch(GetAllBankAccountsAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  };
}

export function GetBankAccount(id) {
  return (dispatch) => {
    getBankAccount(id)
      .then((response) => {
        dispatch(GetAllBankAccountsAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  };
}

// export function UpdateBankAccount(formData, id) {
//   return (dispatch) => {
//     updateBankAccount(formData, id)
//       .then((response) => {
//         dispatch(UpdateBankAccountAction(response.data));
//       })
//       .catch((error) => {
//         const errorMessage = formatError(error.message);
//         console.error(errorMessage);
//       });
//   };
// }

export function DeleteBankAccount(id) {
  return (dispatch) => {
    deleteBankAccount(id)
      .then((response) => {
        dispatch(DeleteBankAccountAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(error(errorMessage));
      });
  };
}

export function CreateBankAccountAction(data) {
  return {
    type: CREATE_BANK_ACCOUNT,
    payload: data,
  };
}

export function GetAllBankAccountsAction(data) {
  return {
    type: GET_ALL_BANK_ACCOUNTS,
    payload: data,
  };
}

export function GetBankAccountAction(data) {
  return {
    type: GET_BANK_ACCOUNT,
    payload: data,
  };
}

export function UpdateBankAccountAction(data) {
  return {
    type: UPDATE_BANK_ACCOUNT,
    payload: data,
  };
}

export function DeleteBankAccountAction(data) {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload: data,
  };
}
