import axiosInstance from "./AxiosInstance";

export async function getAllUsers() {
  const res = await axiosInstance.get('/user');
  return res;
}

export async function getUser(Id) {
  const res = await axiosInstance.get(`/user/${Id}`);
  return res;
}

