import { GET_USER_CONFIRMED_ACTION } from "../actions/UserActions";
import { GET_ALL_USERS_CONFIRMED_ACTION } from "../actions/UserActions";

const initialState = {
  users: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function users(state = initialState, action) {
  if (action.type === GET_ALL_USERS_CONFIRMED_ACTION) {
    return {
      ...state,
      users: action.payload,
      showLoading: false,
    };
  }

  if (action.type === GET_USER_CONFIRMED_ACTION) {
    return {
      ...state,
      users: action.payload,
      showLoading: false,
    };
  }

  return state;
}
