import axiosInstance from "./AxiosInstance";

export function getOrders() {
  return axiosInstance.get('/order');
}

export function getSingleOrder(orderId) {
  return axiosInstance.get(`/order/${orderId}`);
}

export function getMerchantOrders(merchantId) {
  return axiosInstance.get(`/orders/merchant/${merchantId}`);
}

export function getTopOrderItems(merchantId) {
  return axiosInstance.get(`/orders/merchant/get-top-items${merchantId}`);
}

export function deleteOrder(orderId) {
  return axiosInstance.del(`/order/${orderId}`);
}
