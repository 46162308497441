import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { GetAllMerchantsAction, GetMerchantDataAction } from "../../../store/actions/MerchantActions";

import Orders from "./Orders/Orders";
import { Dropdown, Modal } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { GetMerchantOrdersAction } from "../../../store/actions/OrdersActions";
import { set } from "react-hook-form";
import Merchants from "./Merchant/Merchants";
import { Link } from "react-router-dom";

// const sparklinedata = [4, 7, 4, 9, 5, 6, 8, 3, 1, 3, 5, 6];

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "option1":
//       return (state = "Year");
//     //break;
//     case "option2":
//       return (state = "Month");
//     //break;
//     case "option3":
//       return (state = "Week");
//     //break;
//     default:
//       return state;
//   }
// };

function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [state, dispatch] = useReducer(reducer, "Year");
  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const merchantData = useSelector((state) => state.merchant.data);
  const state = useSelector((state) => state);

  // useState
  const [activeOrders, setActiveOrders] = useState(0);
  const [redeemedOrders, setRedeemedOrders] = useState(0);
  const [expiredOrders, setExpiredOrders] = useState(0);
  const [isDashboard, setIsDashboard] = useState(false);
  const [totalRevenue, setTotalRevenue]  = useState(0.00);
  const [filteredMerchants, setFilteredMerchants] = useState([]);
  const [filtered, setFiltered] = useState(false);  


  // Get Merchant data and orders to load on dashboard
  useEffect(() => {
    if (location.pathname === "/admin") {
      dispatch(GetAllMerchantsAction());
    }
  }, [location.pathname]);

  // This is for the Orders component to show data based on which page is rendered
  useEffect(() => {
    setIsDashboard(true);
  }, []);

  function handleSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
  
    if (searchTerm !== '') {
      setFilteredMerchants(
        merchantData.filter((merchant) =>
          merchant.business_name.toLowerCase().includes(searchTerm)
        )
      );
      setFiltered(true);
    } else {
      setFilteredMerchants([]);
      setFiltered(false);
    }
  }

  function addMerchant() {
    navigate("/register-admin");
  }

  return (
    <>
      <div className="row">
        <PageTitle />
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card income-bx">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-8">
                      <p className="font-w500 mb-0"></p>
                      <h4 className="cate-title mb-0">Admin Dashboard</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="card">
              <h4 className="cate-title m-5 mb-1">Merchants</h4>

                <div className="card-body pb-0">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="input-group search-area2 style-1">
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Search merchants..."
              onChange={handleSearch}
            />
          </div>
        </div>
        <button
            type="button"
            className="btn btn-success mt-3 mt-sm-0"
            onClick={() => addMerchant()}
          >
            Add Merchant
          </button>
                    {/* <select className="form-control default-select style-1 w-auto border" >
                                      <option>Month</option>
                                      <option>This Month</option>
                                      <option>Last Month</option>
                                    </select> */}
                    {/* <Dropdown className="deliver-drop">
                                        <Dropdown.Toggle as="div" className="form-control ms-0 default-select border deliver-drop-toggle style2 i-false">
                                            {state} <i className="fas fa-chevron-down drop-toggle-icon" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option1'})}>Year</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option2'})}>Month</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option3'})}>Week</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                  </div>
                  <div className="col-xl-12 d-flex flex-wrap gap-2">
                    {filtered === false && merchantData && merchantData.length > 0 ? (
                      merchantData.map((merchant, index) => (
                        <Merchants key={index} merchant={merchant} />
                      ))
                    ) : (
                      filteredMerchants.map((merchant, index) => (
                        <Merchants key={index} merchant={merchant} />
                      ))
                    )}
  
                    
                    {/* <div className="d-flex align-items-center flex md-nowrap flex-wrap mb-3 mb-md-0">
																						<div className="icon-bx bg-primary d-inline-block text-center me-3">
																								<svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path d="M20 8.5C20 4.09 16.41 0.5 12 0.5C7.58998 0.5 3.99998 4.09 3.99998 8.5C3.99998 12.91 7.58998 16.5 12 16.5C16.41 16.5 20 12.91 20 8.5ZM6.99998 8.5C6.99998 5.74 9.23997 3.5 12 3.5C14.76 3.5 17 5.74 17 8.5C17 11.26 14.76 13.5 12 13.5C9.23997 13.5 6.99998 11.26 6.99998 8.5ZM14.4 18.5H9.59998C4.35998 18.5 0.0999756 22.76 0.0999756 28C0.0999756 28.83 0.769976 29.5 1.59998 29.5H22.4C23.23 29.5 23.9 28.83 23.9 28C23.9 22.76 19.64 18.5 14.4 18.5ZM3.26998 26.5C3.94998 23.64 6.52998 21.5 9.59998 21.5H14.4C17.47 21.5 20.05 23.64 20.73 26.5H3.26998Z" fill="white"/>
																								</svg>
																						</div>
																						<div className="me-3">
																								<p className="mb-0">Order Total</p>
																								<h4 className="font-w600 mb-0">25.307</h4>
																						</div>
																						<div className="card style-3 m-0  mt-sm-0  mt-3 mt-sm-0 ms-md-0 ms-lg-3">
																								<div className="card-body p-3">
																										<div className="d-flex align-items-center justify-content-between mb-2">
																												<span>Target</span>
																												<h6 className="font-w500 mb-0 data">3.982</h6>
																										</div>
																										<div className="progress default-progress">
																												<div className="progress-bar bg-gradient1 progress-animated" style={{width: '40%', height:'10px' }} role="progressbar">
																														<span className="sr-only">45% Complete</span>
																												</div>
																										</div>
																								</div>
																						</div>
																				</div> */}
                    <div className="d-flex align-items-center">
                      {/* <div className="me-4">
																								<ul className="d-flex"> 
																										<li className="text-nowrap"><svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																										<circle cx="6" cy="6.5" r="4.5" fill="white" stroke="#FC8019" strokeWidth="3"/>
																										</svg>This Month
																										</li>
																								</ul>
																								<h4 className="font-w600 mb-0">1324</h4>
																						</div> */}
                      {/* <div>
																								<ul className="d-flex"> 
																										<li className="text-nowrap"><svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																										<circle cx="6" cy="6.5" r="4.5" fill="white" stroke="#EB5757" strokeWidth="3"/>
																										</svg>Last Month
																										</li>
																								</ul>
																								<h4 className="mb-0 font-w600">1324</h4>
																						</div> */}
                    </div>
                  </div>
                  {/* <Orders isDashboard={isDashboard} /> */}
                  {/* <OrderAreaChart />                                     */}
                </div>
              </div>
            </div>
            {/* <div className="col-xl-12">
              <h4 className="cate-title mb-0">Order History</h4>
              <OrderHistory />
            </div> */}
          </div>
        </div>

      </div>
    </>
    );
}
export default Dashboard;
